export const Sdk = window.jsSDK.default

export const requestMobile = async (api, data) => {
    const res = await Sdk.emit(api, data)
    if (res.code === 200) return Promise.resolve(res.data)
    return Promise.reject(res.desc)
}

/*
export async function viewResource(params: {
    index
    list
}): Promise<void> {
    await requestMobile('viewer/picture', {
        active: params.index,
        list: params.list
    })
}*/
