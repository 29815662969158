<template>
  <div class="loginView">
    <div class="images">
<!--      <img src="/images/login/text.png" class="text">-->
      <img src="/images/login/car.png" class="car">
      <img src="/images/appMainView/logo.png" class="logo">
    </div>
    <van-popup v-model="showProvison" round :close-on-click-overlay='false'>
      <provision @cancle='handleCancle' @confirm='handleConfirm'/>
    </van-popup>
  </div>
</template>

<script>
import {oauthLogin, switchLogin, uuidLogin} from "@/api/login";
import {
  agreeUserNotice,
  // addLoginCount,
  loginCount
} from "@/api/suser";

import provision from './components/provision'
import {requestMobile} from "@/utils/shanbusdk";
import {isThunbuApp, isWechatEnterpriseBrowser} from "../../utils/utils";
import {setJumpData} from "@/utils/tempLoginData";

export default {
  name: "login",
  components: {
    provision
  },
  created () {
    // 阻止用户登录
  /*  let oaToken = this.$route.query.oauthToken || '';
    let reg = /\d+/g
    if(!reg.test(oaToken)){
      this.$dialog.alert({message:"社区正在维护中，感谢您的访问！"})
      return;
    }*/

    sessionStorage.setItem("isShanbu", isThunbuApp());
    // 判断
    let loginType = this.$route.query.loginType;
    console.log(loginType,"登录类型")
    // 闪布单点登录，手动获取用户
    if(loginType === "2"){
      // 闪布
      console.log("调用闪布登录！！")
      requestMobile("base/getUserDetail",{}).then(res=>{
        console.log("闪布返回用户信息成功！！",res)
        oauthLogin({oauthToken: res.token}).then(res=>{
          console.log("正常后台登录成功！")
          setJumpData(this.$route.query);
          this.$store.commit('user/SET_USERINFO', res);
          this.getLoginCount()
        })
      })
    }else if(loginType === "3"){
      // http://192.168.200.253:1888/login?loginType=3&&jumpType=artice&articleId=1407785442492928002&uuid=7150e0d09a2111e8b34800505692203e
      console.log("调用UUID闪布登录！！")
      let uuid = this.$route.query.uuid;
      if(isThunbuApp()){
        uuidLogin({uuid}).then(res=>{
          setJumpData(this.$route.query);
          console.log("正常后台登录成功！")
          this.$store.commit('user/SET_USERINFO', res);
          this.getLoginCount()
        })
      }
      // eslint-disable-next-line no-undef
    }else if(loginType === "4" && isWechatEnterpriseBrowser()){
      // 企业微信手机版本
      setJumpData(this.$route.query)
      window.location.replace(`${window.location.protocol}//${window.location.host}/wechat_login.html`)
    }else if(loginType==='10'){
      //切换官方身份或者普通用户
      switchLogin().then(res=>{
        this.$store.commit('user/LOG_OUT');
        this.$store.commit('user/SET_USERINFO', res);
        this.getLoginCount()
      })
    } else {
      let oauthToken = this.$route.query.oauthToken;
      if(oauthToken instanceof Array){
        // 正式环境
        oauthToken = oauthToken[oauthToken.length-1]
      }
      if(oauthToken){
        oauthLogin({oauthToken}).then(res=>{
          setJumpData(this.$route.query);
          this.$store.commit('user/SET_USERINFO', res);
          this.getLoginCount()
        })
      }
    }
  },
  data() {
    return {
      showProvison: false
    }
  },
  methods: {
    async getLoginCount() {
      const res = await loginCount()
      if(res === 0) {
        this.showProvison = true
      } else {
        this.$router.replace("/")
      }
    },
    handleCancle() {
      // this.$router.back()
      window.close()
    },
    async handleConfirm() {
      // await addLoginCount()
      await agreeUserNotice()
      this.$router.replace("/")
    }
  }
}
</script>

<style lang="scss" scoped>
.loginView {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  .images {
    text-align: center;

    .text {
      width: 80px;
      margin-bottom: 100px;
      margin-top: 40px;
    }
    .car {
      width: 326px;
      /*margin-bottom: 80px;*/
    }
    .logo {
      width: 164px;
    }
  }
  .van-popup {
    width: 80%;
    padding: 20px;
    height: 80%;
  }
}
</style>
